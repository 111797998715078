import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthModule, AuthService } from '@capturum/auth';
import { CapturumSharedModule, ToastService } from '@capturum/ui/api';
import { CapturumInputModule } from '@capturum/ui/input';
import { LoadingService } from '@capturum/ui/loader';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { IpPermission } from '@core/models/ip-permission.model';
import { CustomLoginService } from '@core/services/custom-login.service';
import { CustomUserService } from '@core/services/custom-user.service';
import { GlobalSearchService } from '@core/services/global-search.service';
import { IpPermissionService } from '@core/services/ip-permission.service';
import { User } from '@features/manage-user/models/user.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AssetsPipe } from '@shared/pipes/assets.pipe';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, first, map, takeUntil } from 'rxjs/operators';
import { ExportJobService } from '../../services/export-job.service';
import { SettingService } from '@capturum/complete';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CapturumInputModule,
    AuthModule,
    RouterLinkActive,
    RouterLink,
    OverlayPanelModule,
    AsyncPipe,
    TranslateModule,
    CapturumSharedModule,
    AssetsPipe,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public routes: typeof AppRoutes = AppRoutes;
  public form: UntypedFormGroup;
  public appRoutes = AppRoutes;
  public authenticationState$: Observable<boolean>;
  public ipPermission: IpPermission;
  public userDomains$: Observable<string[]>;
  public userDisplayName: string;
  public placeholder = this.settingService.getValue('radboud.use_advanced_search')
    ? this.translate.instant('radboud.header.search.placeholder')
    : this.translate.instant('radboud.header.global-search.placeholder');

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public router: Router,
    private loginService: CustomLoginService,
    private authService: AuthService,
    private toast: ToastService,
    private translate: TranslateService,
    private loader: LoadingService,
    private fb: UntypedFormBuilder,
    private globalSearchService: GlobalSearchService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private ipPermissionService: IpPermissionService,
    private userService: CustomUserService,
    private exportJobService: ExportJobService,
    private settingService: SettingService,
  ) {
    this.authenticationState$ = this.authService.getAuthenticationState();
    this.currentUser = this.authService.getUser() as unknown as User;
    this.userDisplayName =
      [this.currentUser?.first_name, this.currentUser?.middle_name, this.currentUser?.last_name]
        .filter(Boolean)
        .join(' ') +
      ' (' +
      (this.settingService.getValue('radboud.display_user_name')
        ? this.currentUser?.username
        : this.currentUser?.email) +
      ')';
  }

  public ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.userDomains$ = this.userService.get(this.currentUser.id, { include: ['domains'] }).pipe(
        map((result) => {
          return (result as unknown as User).domains?.map((domain) => {
            return domain.short_name ?? domain.name;
          });
        }),
      );
    }

    this.form = this.createForm();

    this.form
      .get('search')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(350),
        filter((value) => {
          return value !== null;
        }),
      )
      .subscribe((value) => {
        this.routeToGlobalSearchPage();
        this.globalSearchService.setSearchTerm(value);
      });

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
      )
      .subscribe((event: NavigationEnd) => {
        // If the user routes away from global search page, clear the search query
        if (event.url.indexOf(AppRoutes.globalSearch) < 0) {
          this.form.get('search').patchValue(null);
          this.cdr.detectChanges();
        }
      });

    this.ipPermissionService
      .getIpPermissionState()
      .pipe(first())
      .subscribe((ipPermission) => {
        return (this.ipPermission = ipPermission);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createForm(): UntypedFormGroup {
    return this.fb.group({
      search: [null],
    });
  }

  /**
   * Login with a redirectURL
   *
   * @return void
   */
  public routeToLogin(): void {
    if (this.ipPermission?.is_allowed_to_login) {
      this.router.navigate([this.routes.authLogin]);
    } else {
      this.toast.error(
        this.translate.instant('toast.error.permission-denied'),
        this.translate.instant('radboud.login.exception.ip-not-in-range', { ip: this.ipPermission?.origin }),
      );
    }
  }

  public loginWithSURFconext(): void {
    this.loginService.loginWithSURFconext();
  }

  /**
   * Logout and redirect to login screen
   */
  public logout(): void {
    this.loader.toggleLoading(true);

    this.authService.logout().subscribe(
      (response) => {
        this.loader.hideLoader();
        this.exportJobService.stopExportJob();

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/'], { relativeTo: this.route });

          localStorage.clear();
        });
      },
      () => {
        return this.loader.hideLoader();
      },
    );
  }

  /**
   * Check whether the route matches the global search page, if it doesn't, route to it
   *
   * @return void
   */
  private routeToGlobalSearchPage(): void {
    if (this.router.url.indexOf(AppRoutes.globalSearch) < 0) {
      this.router.navigate([AppRoutes.globalSearch]);
    }
  }
}
